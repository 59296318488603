
import { computed, defineComponent, onMounted, ref } from "vue"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/plugins/i18n";
import { Field, Form } from "vee-validate";
import * as Validations from "yup";
import CashModule from "@/store/modules/sales copy/modules/cash";
import { getModule } from "vuex-module-decorators";
// import Select from "@/components/forms/Select.vue"
import NoDataMessage from "@/components/shared/NoDataMessage.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {VMoney} from 'v-money'


export default defineComponent({
    components: {
        Field,
        Form,
        NoDataMessage
    },
    setup() {
        const money = ref(({//en algun momento se usara multilenguaje y aqui pueden aplicar los cambios
            decimal: '.',
            thousands: ',',
            prefix: '$ ',
            suffix: '',
            precision: 2,
            masked: false
        }))
        const moduleCash = getModule(CashModule);

        moduleCash.GET_INFO_CASH();
        moduleCash.GET_CASHREGISTER_COMBO();

        //Schema
        const validationSchema = Validations.object().shape({
            Cheques: Validations.string()
                // .default(0)
                // .min(1, 'Ingrese una cantidad minima de 1')
                // .typeError('Ingrese una cantidad valida')
                .label("Cheques"),
            transferencia: Validations.string()
                // .default(0)
                // .min(1, 'Ingrese una cantidad minima de 1')
                // .typeError('Ingrese una cantidad valida')
                .label("transferencia"),
            CreditCard: Validations.string()
                // .default(0)
                // .min(1, 'Ingrese una cantidad minima de 1')
                // .typeError('Ingrese una cantidad valida')
                .label("CreditCard"),
            DebitCard: Validations.string()
                // .default(0)
                // .min(1, 'Ingrese una cantidad minima de 1')
                // .typeError('Ingrese una cantidad valida')
                .label("DebitCard"),
            WithdrawalsNote: Validations.string()
                // .default(0)
                // .min(1, 'Ingrese una cantidad minima de 1')
                // .typeError('Ingrese una cantidad valida')
                .label("WithdrawalsNote"),
            Deposit: Validations.string()
                // .default(0)
                // .min(1, 'Ingrese una cantidad minima de 1')
                // .typeError('Ingrese una cantidad valida')
                .label("Deposit"),
            TotalMXN: Validations.string()
                // .default(0)
                // .min(1, 'Ingrese una cantidad minima de 1')
                // .typeError('Ingrese una cantidad valida')
                .label("TotalMXN")
            // TotalUSD: Validations.number()
            //                 .default(0)
            //                 .min(1, 'Ingrese una cantidad minima de 1')
            //                 .typeError('Ingrese una cantidad valida')
            //                 .label("TotalUSD")
        });

        //metodos
        const submitOpemCash = (data: any) => {
            let actionModule: Promise<boolean>;

            Swal.fire({
                title: '¿Está seguro de cerrar la caja?',
                text: "Se procederá a cerrar la caja y no podrá revertir la acción",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#000000',
                cancelButtonColor: '#7E829',
                confirmButtonText: 'Si, cerrar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    actionModule = moduleCash.CLOSE_CASH({
                        cashierId: infoCashUser.value.id,
                        cashRegisterId: infoCashUser.value.cashRegisterId,
                        bankDepositTotal: parseFloat(data.Deposit.replace(/[^\d.]/g, '')),
                        creditCardTotal: parseFloat(data.CreditCard.replace(/[^\d.]/g, '')),
                        debitCardTotal: parseFloat(data.DebitCard.replace(/[^\d.]/g, '')),
                        dollarCashTotal: 0, //data.TotalUSD
                        payChecksTotal: parseFloat(data.Cheques.replace(/[^\d.]/g, '')) ,
                        pesoCashTotal: parseFloat(data.TotalMXN.replace(/[^\d.]/g, '')),
                        returnNotesTotal: parseFloat(data.WithdrawalsNote.replace(/[^\d.]/g, '')),
                        transferTotal: parseFloat(data.transferencia.replace(/[^\d.]/g, ''))
                    });

                    actionModule.then((isCorrect) => {
                        if (isCorrect) {
                            moduleCash.GET_INFO_CASH();
                        }
                    });
                }
            });

            // if(infoCashUser.value.isActive){
            //      Swal.fire({
            //         text: "No se puede inicializar la caja, usted cuenta con una caja abierta.",
            //         icon: "warning",
            //         buttonsStyling: false,
            //         confirmButtonText: "Acceptar",
            //         customClass: {
            //         confirmButton: "btn btn-primary",
            //         },
            //     }).then(() => {
            //         console.log("ok");
            //     });
            // }else{
            //     let actionModule: Promise<boolean>;

            //     // actionModule = moduleCash.OPEN_CASH({
            //     //     cashierId: infoCashUser.value.id,
            //     //     cashRegisterId: data.cashRegisterId,
            //     //     // supervisorId: '08daa7fe-5f84-4dbb-8844-5002c7331fb9',
            //     //     initialBalance: data.initialBalance
            //     // });

            //     // actionModule.then((isCorrect) => {
            //     //     if(isCorrect){
            //     //         moduleCash.GET_INFO_CASH();
            //     //     }
            //     // });
            // }
        }

        const infoCashUser = computed(() => moduleCash.getUserInfoCash);
        const lstCash = computed(() => moduleCash.getCashRegisters);

        onMounted(() => {
            setCurrentPageBreadcrumbs(translate('BREADCRUMB.CLOSECASH'), [translate('BREADCRUMB.MODULES')]);
        });
        return {
            validationSchema
            , infoCashUser
            , lstCash
            , money
            
            , submitOpemCash
        }
    }, 
    directives:{
        money: VMoney
    }
})
